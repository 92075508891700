import React, { useState, useEffect } from "react"
import "table.css"
import {
  Coin,
  Coins,
  CreateTxOptions,
  Fee,
  MsgSend,
  SignerInfo,
  int,
} from "@terra-money/terra.js"
import { MsgExecuteContract, MsgInstantiateContract } from "@terra-money/terra.js"
import { TxResult, useWallet } from "@terra-money/wallet-provider"
import { useNetwork, useContract, useAddress, useConnectModal } from "hooks"
import { useLCDClient } from "layouts/WalletConnectProvider"
import useGasPrice from "rest/useGasPrice"
import styled from "styled-components"
import Container from "components/Container"
import Result from "forms/Result"
import styles from "components/Modal.module.scss"
import { useModal } from "components/Modal"
import {
  CreateTxFailed,
  TxFailed,
  TxUnspecifiedError,
  UserDenied,
} from "@terra-dev/wallet-types"
import { number } from "libs/math"


const emojiLegend = [
  { label: "> 8% Savings", emoji: "🤯" },
  { label: "6-8% Savings", emoji: "😁" },
  { label: "4-6% Savings", emoji: "😀" },
  { label: "2-4% Savings", emoji: "😊" },
  { label: "0-2% Savings", emoji: "🙂" },
  { label: "0-1% Premium", emoji: "🤨" },
  { label: "1-5% Premium", emoji: "🙄" },
  { label: "> 5% Premium", emoji: "😤" },
];

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding-left: 20px; 
  padding-right: 20px;
  `

function PtoP_base() {
  const wallet = useWallet()
  const walletAddress = useAddress()
  const connectModal = useConnectModal()
  const { terra } = useLCDClient()
  const { gasPrice } = useGasPrice("uluna")
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50) // Number of items to display per page
  const [isLoading, setIsLoading] = useState(true)
  let [price, setPrice] = useState("")
  let [quantity, setQuantity] = useState("")
  const [tax, setTax] = useState("")
  const [total, setTotal] = useState("")
  const [rate, setRate] = useState("")
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [result, setResult] = useState()
  const [buyPrice, setBuyPrice] = useState(0); // State to store the fetched spot price
  const [contractAddress, setContractAddress] = useState();
  const BASEtokenAddress = 'terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m';
  //const codeId = 8023; //first one, flawed, left some BASE behind
  //const codeId = 8060; //second one, all BASE is sent out (but LUNC still left behind)
  const codeId = 8376; //added withdraw native (to get all LUNC out)

  const handleAskCreate = async () => {

    if (price == "No price found for the current wallet" || price == "") {
      price = '200'
    }
    quantity = '1'


    const senderAddress = walletAddress;
    const initMsg = {
      cw20_address: BASEtokenAddress, denom: "uluna", price: (Math.floor(Number(price) * 1000000)).toString()
    };

    const label = "P2P_" + `${senderAddress.slice(0, 4)}...${senderAddress.slice(-4)}`;

    // Create a MsgInstantiateContract message to instantiate the contract
    const instantiateMsg = new MsgInstantiateContract(
      senderAddress,
      senderAddress,
      codeId,
      initMsg
    );

    instantiateMsg.label = label;

    const msgs = [instantiateMsg];

    // Include this message in your transaction's `msgs` array
    const txOptions = {
      msgs,
      memo: "Create BASE P2P Contract",
      gasPrices: `${gasPrice}${"uluna"}`,
    };

    // Send the transaction to instantiate the contract
    terra.tx.create([{ address: walletAddress }], txOptions)
      .then((signMsg) => {
        const fee = signMsg.auth_info.fee.amount.add(
          new Coin("uluna", Math.floor(Number(10) * 0.0051 * 1000000) + 20000000)
        );

        const biggerFee = new Coin(
          "uluna",
          Math.floor(Number(fee.get("uluna")?.amount) * 1.1)
        );

        txOptions.fee = new Fee(500000, new Coins([biggerFee]));

        return wallet.post(txOptions, walletAddress);
      })
      .then((extensionResult) => {
        if (extensionResult) {
          //setContractAddress(extensionResult.contract_address);
          setResult(extensionResult);
          setIsResultModalOpen(true);
        }
      })
      .catch((error) => {
        console.error(error);
        const errorMessage = error.message || "An error occurred during the transaction";
        setResult(errorMessage);
        setIsResultModalOpen(true);
      });
  }

  const handleAskEdit = async () => {

    if (price == "No price found for the current wallet" || quantity == "No quantity found for the current wallet") {
      return
    }

    const senderAddress = walletAddress;

    //Set price message
    const executeMsg = { set_price: { price: (Math.floor(Number(price) * 1000000)).toString(), denom: "uluna" } };
    // Create a MsgExecuteContract message for interactions with the instantiated contract
    const executeContractMsg = new MsgExecuteContract(
      senderAddress,
      getMyContractAddress(),
      executeMsg,
    );

    /* This works, but it is for a transfer not a send
    // Create a MsgExecuteContract message for CW20 Transfer
    const cw20ExecuteMsg = { transfer: { amount: (Math.floor(Number(quantity) * 1000000)).toString(), recipient: getMyContractAddress() } };
    // Transfer BASE tokens.
    const cw20TransferMsg = new MsgExecuteContract(
      senderAddress,
      BASEtokenAddress,
      cw20ExecuteMsg,
    ); */

    const oldQuantity = Number(getMyQuantity());
    const newQuantity = Number(quantity);
    let msgs = [];

    if (oldQuantity < newQuantity) {
      const deltaQuantity = Math.floor((newQuantity - oldQuantity) * 1000000);

      // Create a MsgExecuteContract message for CW20 Send
      const cw20ExecuteMsg = { send: { amount: deltaQuantity.toString(), contract: getMyContractAddress(), msg: "" } };

      // Send BASE tokens.
      const cw20SendContractMsg = new MsgExecuteContract(
        senderAddress,
        BASEtokenAddress,
        cw20ExecuteMsg,
      );

      msgs = [executeContractMsg, cw20SendContractMsg];

    } else if (oldQuantity > newQuantity) {
      const refundQuantity = Math.floor((oldQuantity - newQuantity) * 1000000);

      // Create a MsgExecuteContract message for CW20 withdraw
      const cw20ExecuteMsg = { withdraw_some: { amount: refundQuantity.toString() } };

      // Send BASE tokens.
      const cw20WithdrawContractMsg = new MsgExecuteContract(
        senderAddress,
        getMyContractAddress(),
        cw20ExecuteMsg,
      );

      msgs = [executeContractMsg, cw20WithdrawContractMsg];
    } else {
      //same quantity so just send price
      msgs = [executeContractMsg];
    }

    // Include this message in your transaction's `msgs` array
    const txOptions = {
      msgs,
      memo: "Edit P2P Contract",
      gasPrices: `${gasPrice}${"uluna"}`,
    };


    // Send the transactions (Price set and Send [transfer is commented out on purpose])
    terra.tx.create([{ address: walletAddress }], txOptions)
      .then((signMsg) => {
        const fee = signMsg.auth_info.fee.amount.add(
          new Coin("uluna", Math.floor(Number(10) * 0.0051 * 1000000) + 20000000)
        );

        const biggerFee = new Coin(
          "uluna",
          Math.floor(Number(fee.get("uluna")?.amount) * 1.1)
        );

        txOptions.fee = new Fee(500000, new Coins([biggerFee]));

        const tx_result = wallet.post(txOptions, walletAddress);

        return tx_result;
      })
      .then((extensionResult) => {
        if (extensionResult) {
          setResult(extensionResult);
          setIsResultModalOpen(true);
        }
      })
      .catch((error) => {
        console.error(error);
        const errorMessage = error.message || "An error occurred during the transaction";
        setResult(errorMessage);
        setIsResultModalOpen(true);
      });

  }

  useEffect(() => {
    // Fetch the spot price when the component mounts
    fetchSpotPrice();
  }, []);

  const fetchSpotPrice = () => {
    const url = "https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m/smart/eyJjdXJ2ZV9pbmZvIjp7fX0=";
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let fetchedPrice = Number(data.data.spot_price) / 1000000;
        fetchedPrice = fetchedPrice * 1.058734; //account for taxes to get to buy price)
        setBuyPrice(fetchedPrice);
      })
      .catch((error) => console.error("Error fetching spot price:", error));
  };

  useEffect(() => {
    fetchContractData()
      .then(() => {
        // After fetching data, set the contractAddress based on some logic.
        if (walletAddress) {
          const currentRow = tableData.find((row) => row.owner === walletAddress);
          if (currentRow) {
            setContractAddress(currentRow.contract);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, []); // Fetch data when the component mounts

  // Use useEffect to populate price and quantity when both addresses are available
  useEffect(() => {
    if (walletAddress && !isLoading) {
      const initialPrice = getMyPrice();
      const initialQuantity = getMyQuantity();
      setPrice(initialPrice);
      setQuantity(initialQuantity);
    }
  }, [walletAddress, isLoading]);



  useEffect(() => {
    if (price && quantity) {
      const preTax = parseFloat(price) * parseFloat(quantity)
      const projectFee = preTax * 0.025; //for the project
      const chainTax = (preTax + projectFee) * 0.0051
      const gasFee = (29 * 2.0); //for gas price of 29 & limit 2,000,000
      const bcFee = 72.5; //min transaction fee
      const calculatedTax = projectFee + chainTax + gasFee + bcFee
      const calculatedTotal = preTax + calculatedTax
      const calculatedRate = (calculatedTotal / parseFloat(quantity)) //* 1.0075

      setTax(calculatedTax.toFixed(6))
      setTotal(calculatedTotal.toFixed(6))
      setRate(calculatedRate.toFixed(6))
      //const { gasPrice } = useGasPrice("uluna")
    }
  }, [price, quantity])


  const fetchContractData = async () => {
    setIsLoading(true);

    try {
      const contractsResponse = await fetch(
        `https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/code/${codeId}/contracts`
      );
      const contractsData = await contractsResponse.json();

      if (contractsData && contractsData.contracts) {
        // Create an array of Promises for fetching data for each contract address
        const fetchPromises = contractsData.contracts.map(async (contractAddress) => {
          const contractUrl = `https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/${contractAddress}/smart/eyJnZXRfaW5mbyI6e319`;
          const response = await fetch(contractUrl);
          const data = await response.json();

          if (data && data.data) {

            // Calculate tax, total, and rate based on price and quantity
            const price = parseFloat(data.data.price.amount) / 1000000;
            const quantity = parseFloat(data.data.balance) / 1000000;

            // Check if quantity is greater than zero before adding to validResults
            const preTax = price * quantity;
            const projectFee = preTax * 0.025;
            const chainTax = (preTax + projectFee) * 0.0051;
            const gasFee = (29 * 2.0); //for gas price of 29 & limit 2,000,000
            const bcFee = 72.5; //min transaction fee
            const total = preTax + projectFee + chainTax + gasFee + bcFee;
            const rate = (total / quantity)
            const tax = total - preTax;

            return {
              owner: data.data.owner,
              contract: contractAddress,
              price,
              quantity,
              tax,
              total,
              rate,                                                                  
            };

          }
        });

        // Wait for all Promises to resolve
        const results = await Promise.all(fetchPromises);

        // Filter out any undefined results
        const validResults = results.filter((result) => result);

        // Update the state with the new array
        setTableData(validResults);
      }

      setIsLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const getMyContractAddress = () => {
    if (walletAddress) {
      const currentRow = tableData.find((row) => row.owner === walletAddress);
      if (currentRow) {
        return currentRow.contract;
      } else {
        return undefined;
      }
    } else {
      return "N/A";
    }
  };

  const getMyPrice = () => {
    if (walletAddress) {
      const currentRow = tableData.find((row) => row.owner === walletAddress);
      if (currentRow) {
        return currentRow.price;
      }
    }
    return "No price found for the current wallet";
  };

  const getMyQuantity = () => {
    if (walletAddress) {
      const currentRow = tableData.find((row) => row.owner === walletAddress);
      if (currentRow) {
        return currentRow.quantity;
      }
    }
    return "No quantity found for the current wallet";
  };

  // Function to generate table rows for the current page
  const renderTableRows = () => {
    // Sort the tableData array based on the LUNC/BASE ratio (rate)
    const sortedData = tableData.sort((a, b) => a.rate - b.rate);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredData = sortedData.filter(
      (row) => row.quantity > 1 || row.owner === walletAddress
    );
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    return currentItems.map((row, index) => {
      const sellerAddr = `${row.owner.slice(0, 4)}...${row.owner.slice(-4)}`;

      // Determine the button text based on wallet connection and seller's address
      let buttonText = "Connect"; // Default text for unconnected wallet
      let buttonClass = ""; // Default class for unconnected wallet
      if (walletAddress) {
        if (walletAddress === row.owner) {
          // Check if the quantity is non-zero
          if (row.quantity > 0) {
            buttonText = "Cancel"; // Wallet address matches seller's address, and quantity is non-zero
            buttonClass = "cancel-button"; // Add a class for the "Cancel" button
          } else {
            buttonText = "N/A"; // Wallet address matches seller's address, but quantity is zero
            buttonClass = "na-button"; // Add a class for the "N/A" button
          }
        } else {
          buttonText = "Buy"; // Wallet address does not match seller's address
          buttonClass = "buy-button"; // Add a class for the "Buy" button
        }
      }

      const contractAddr = `<a href="https://finder.terraclassic.community/mainnet/address/${row.contract}">${row.contract.slice(0, 4)}...${row.contract.slice(-4)}</a>`;

      let differenceText = "";
      let priceClass = "price-diff-white";
      let arrow = "";
      // Calculate the percentage difference
      if (row.quantity > 0) {
        const priceDifference = (((row.total / row.quantity) - buyPrice) / buyPrice) * 100;
        differenceText = priceDifference.toFixed(2) + "%";
        priceClass = priceDifference < 0 ? "price-diff-green" : "price-diff-red";
        //arrow = priceDifference < 0 ? "↓" : "↑";
        let emoji;

        if (-2 <= priceDifference && priceDifference < 0) {
          emoji = "🙂";
        } else if (-4 <= priceDifference && priceDifference < -2) {
          emoji = "😊";
        } else if (-6 <= priceDifference && priceDifference < -4) {
          emoji = "😀";
        } else if (-8 <= priceDifference && priceDifference < -6) {
          emoji = "😁";
        } else if (priceDifference < -8) {
          emoji = "🤯";
        } else if (0 <= priceDifference && priceDifference <= 1) {
          emoji = "🤨";
        } else if (1 < priceDifference && priceDifference <= 5) {
          emoji = "🙄";
        } else {
          emoji = "😤";
        }

        arrow = emoji; // Set arrow to the selected emoji
      }

      return (
        <tr key={index}>
          <td className={priceClass}>
            {arrow} {differenceText}
          </td>
          <td>
            <button
              onClick={buttonText !== 'N/A' ? () => handleActionClick(row, buttonText) : null}
              className={`input-row-button ${buttonClass === 'cancel-button' ? 'cancel-button' : buttonClass === 'na-button' ? 'na-button' : 'buy-button'}`}
            >
              {buttonText}
            </button>
          </td>
          <td>{addCommasToNumber(row.price.toFixed(6))}</td>
          <td>{addCommasToNumber(row.quantity.toFixed(6))}</td>
          {/*<td>{addCommasToNumber(row.tax.toFixed(6))}</td>*/}
          <td>{addCommasToNumber(row.total.toFixed(6))}</td>
          <td>{addCommasToNumber(row.rate.toFixed(6))}</td>
          <td>{sellerAddr}</td>
          <td dangerouslySetInnerHTML={{ __html: contractAddr }} />
        </tr>
      );
    });
  };

  const handleActionClick = (row, actionType) => {
    const executeMsg = {};

    if (walletAddress) {
      try {
        if (actionType === "Buy") {
          executeMsg.buy = { denom: "uluna", price: (Math.floor(Number(row.price) * 1000000)).toString() };
        } else if (actionType === "Cancel") {
          executeMsg.withdraw_all = {};
        }

        let msgs = [];
        let memo = "";
        if (actionType === "Buy") {
          const msg = new MsgExecuteContract(
            walletAddress,
            row.contract.toString(),
            executeMsg,
            { uluna: Math.floor(parseFloat(`${row.total}`) * 1000000).toString() }
          );
          msgs = [msg];
          memo = "Buy P2P Offer";
        } else if (actionType === "Cancel") {
          const msg = new MsgExecuteContract(
            walletAddress,
            row.contract.toString(),
            executeMsg
          );
          msgs = [msg];
          memo = "Cancel P2P Offer";
        }

        const chain_tax = Math.floor(Number(row.total) * 0.0051 * 1000000) + 72500000
        
        // Create the Fee object
        const fee = new Fee(500000, new Coins([new Coin("uluna", chain_tax.toString())]))

        const txOptions = {
          msgs,
          memo,
          gasPrices: `${gasPrice}${"uluna"}`,
          gasAdjustment: "1.8",
          feeDenoms: ["uluna"], // Specify the fee denomination
          fee: fee,
        };

        terra.tx.create([{ address: walletAddress }], txOptions)
          .then((signMsg) => {
/*            const fee = signMsg.auth_info.fee.amount.add(
              new Coin("uluna", Math.floor(Number(row.total) * 0.0051 * 1000000) + 72500000)
            );

            const biggerFee = new Coin(
              "uluna",
              Math.floor(Number(fee.get("uluna")?.amount) * 1.1)
            );

            txOptions.fee = new Fee(500000, new Coins([biggerFee]));
*/
            return wallet.post(txOptions, walletAddress);
          })
          .then((extensionResult) => {
            if (extensionResult) {
              setResult(extensionResult);
              setIsResultModalOpen(true);
            }
          })
          .catch((error) => {
            console.error(error);
            const errorMessage = error.message || "An error occurred during the transaction";
            setResult(error);
            setIsResultModalOpen(true);
          });
      } catch (error) {
        console.error(error);
        setResult(error);
      }
    } else {
      connectModal.open();
    }
  };
 
  const handleFailure = () => {
    setTimeout(() => { }, 125)
    //setResult("There was a problem. Please try again later.")
    window.location.reload()
  }

  function addCommasToNumber(number) {
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // Function to handle items per page change
  const handleItemsPerPageChange = (e) => {
    const value = parseInt(e.target.value)
    setItemsPerPage(value)
    setCurrentPage(1) // Reset to first page when changing items per page
  }

  // Calculate the total number of pages
  const totalPages = Math.ceil(tableData.length / itemsPerPage)

  // Generate page number buttons
  const renderPageNumbers = () => {
    const pageNumbers = []
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </button>
      )
    }
    return pageNumbers
  }

  // Generate items per page options
  const renderItemsPerPageOptions = () => {
    const options = [25, 50, 100]
    return options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ))
  }

  const buttonText = walletAddress
    ? (getMyContractAddress()
      ? "Edit"
      : "Create")
    : "Connect";

  return (
    <Wrapper>
      {isResultModalOpen && (
        <div className="modal-overlay">
          <Container sm>
            <Result
              response={result}
              error={(result instanceof Error && result) || result === "User Denied" ? result : undefined}
              parserKey={"default"}
              onFailure={handleFailure}
            />
          </Container>
        </div>
      )}

      <div className="table-container">
        <h1table> BASE P2P Marketplace</h1table>
        <h2table>Sell BASE - Create / Edit an Offer</h2table>

        <div className="input-section">
          {buttonText === "Edit" && (
            <>
              <div className="input-row">
                <div className="input-group">
                  <label>Price:</label>
                  <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    style={{ borderColor: 'orange' }} // Add the orange border
                  />
                </div>
                <div className="input-group">
                  <label>Quantity:</label>
                  <input
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    style={{ borderColor: 'orange' }} // Add the orange border
                  />
                </div>
              </div>
              {/* Third Row */}
              <div className="input-row">
                <div className="input-group">
                  <label>Taxes:</label>
                  <input type="number" value={tax} readOnly />
                </div>
                <div className="input-group">
                  <label>Total:</label>
                  <input type="number" value={total} readOnly />
                </div>
              </div>

              {/* Fourth Row */}
              <div className="input-row">
                <div className="input-group">
                  <label>Rate:</label>
                  <input type="number" value={rate} readOnly />
                </div>
                <div className="input-group">
                  <label>&nbsp;</label> {/* Empty label for spacing */}
                  <button
                    onClick={walletAddress
                      ? (getMyContractAddress()
                        ? handleAskEdit
                        : handleAskCreate)
                      : () => connectModal.open()}
                    type="submit"
                    className="submit-button"
                  >
                    {buttonText}
                  </button>
                </div>
              </div>
            </>
          )}
          {buttonText !== "Edit" && (
            <div className="input-row">
              <div className="input-group">
                <label>Price:</label>
                <input
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  disabled={buttonText === "Connect"}
                  style={{ borderColor: 'orange' }} // Add the orange border
                />
              </div>
              <div className="input-group">
                <label>&nbsp;</label>
                <button
                  onClick={walletAddress
                    ? (getMyContractAddress()
                      ? handleAskEdit
                      : handleAskCreate)
                    : () => connectModal.open()}
                  type="submit"
                  className="submit-button"
                >
                  {buttonText}
                </button>
              </div>
            </div>
          )}
        </div>
        <div>
          {/* Fixed header */}
          <h2table className="padded-header"> Buy BASE - Select an Offer</h2table>

        {/* Legend for Emojis (Horizontal) */}
        <div className="emoji-legend-horizontal">
          <h3>Emoji Legend:</h3>
          <div className="emoji-list">
            {emojiLegend.map((item, index) => (
              <div key={index} className="emoji-item">
                <span role="img" aria-label={item.label}>
                  {item.emoji}
                </span>{" "}
                {item.label}
              </div>
            ))}
          </div>
        </div>

          <div className="table-wrapper">
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  height: "100vh",
                }}
              >
                <p style={{ fontSize: "3em", fontWeight: "bold" }}>Loading...</p>
              </div>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th className="wider">Savings</th>
                    <th className="wider">Action</th>
                    <th className="wider">BASE Price</th>
                    <th className="wider">BASE Qty</th>
                    {/*<th className="wider">Tax+Fees</th>*/}
                    <th className="wider">Total LUNC</th>
                    <th className="wider">LUNC/BASE</th>
                    <th className="wider">Seller</th>
                    <th className="wider">Contract</th>
                  </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
              </table>
            )}
          </div>
          <h2 className="padded-header">&nbsp;</h2>
          <div className="pagination">
            <div className="items-per-page">
              <label>Items per page:</label>
              <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                {renderItemsPerPageOptions()}
              </select>
            </div>
            <div className="page-numbers">{renderPageNumbers()}</div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default PtoP_base;

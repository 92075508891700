import React, { memo, useEffect, useState } from "react"
import SwapPage from "../components/SwapPage"
import SwapForm from "../forms/SwapForm"
import Container from "../components/Container"
import { useSearchParams } from "react-router-dom"
import useMigration from "hooks/useMigration"

export enum Type {
  "SWAP" = "swap",
  "PROVIDE" = "provide",
  "WITHDRAW" = "withdraw",
}

const Swap = () => {
  const { isMigrationPage } = useMigration()
  const [searchParams, setSearchParams] = useSearchParams()

  const type = searchParams.get("type") as Type
  const tabs = {
    tabs: isMigrationPage
      ? [
          {
            name: "migration",
            title: "Migration",
          },
        ]
      : [
          { name: Type.SWAP, title: "Swap" },
        ],
    selectedTabName: isMigrationPage ? "migration" : type,
  }

  let [month_apr, setMonth_Apr] = useState(0)
  let [year_apy, setYear_Apy] = useState(0)
  let [biWeek_apr, setBiWeek_Apr] = useState(0)
  let [closePrice_30day, setClosePrice_30day] = useState(0)
  let [closePrice_15day, setClosePrice_15day] = useState(0)

  const handleCurrentPriceChange = (currentPrice: number) => {
    // Do something with the currentPrice in the parent component
    //console.log("Updated currentPrice in parent:", currentPrice)

    if (currentPrice !== 0) {
      const calculatedMonthApr = (currentPrice / closePrice_30day - 1) * 100;
      const calculatedBiWeekApr = (currentPrice / closePrice_15day - 1) * 100;
  
      setMonth_Apr(calculatedMonthApr);
      setBiWeek_Apr(calculatedBiWeekApr);
  
      let maxApr;
      if (calculatedMonthApr > calculatedBiWeekApr) {
          maxApr = calculatedMonthApr;
          setYear_Apy(maxApr * 12);
      } else {
          maxApr = calculatedBiWeekApr;
          setYear_Apy(maxApr * 26);
      }

      setYear_Apy(((currentPrice / 80.5) - 1) * 100)

  }
}

  const handleClosePriceChange_30day = (closePrice_30day: number) => {
    // Do something with the closePrice in the parent component
    //console.log("Updated closePrice in parent:", closePrice_30day)
    setClosePrice_30day(closePrice_30day)
  }

  const handleClosePriceChange_15day = (closePrice_15day: number) => {
    // Do something with the closePrice in the parent component
    console.log("Updated closePrice in parent:", closePrice_15day)
    setClosePrice_15day(closePrice_15day)
  }

  useEffect(() => {
    if (!isMigrationPage) {
      if (
        !type ||
        !Object.keys(Type)
          .map((key) => Type[key as keyof typeof Type])
          .includes(type)
      ) {
        searchParams.set("type", Type.SWAP)
        setSearchParams(searchParams, { replace: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isMigrationPage])

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "px",
          fontSize: "28px",
        }}
      >
        <h1>Automatic LUNC Staking</h1>
        <div style={{ color: "#CD6141", fontSize: "16px" }}>
</div>
          {//biWeek_apr !== Infinity &&
  //month_apr !== Infinity &&
  //year_apy !== Infinity &&
  //biWeek_apr > 0 &&
  //month_apr > 0 &&
  //year_apy > 0 && (
    <h2 style={{ color: "#CD6141", fontSize: "16px" }}>
      {/*{biWeek_apr.toFixed(2)}% (14D) | {month_apr.toFixed(2)}% (30D) | {year_apy.toFixed(2)}% APY*/}
      {year_apy.toFixed(2)}% APY
    </h2>
//)
}
      </div>

      <br></br>

      <SwapPage
        onCurrentPriceChange={handleCurrentPriceChange}
        onClosePriceChange_30day={handleClosePriceChange_30day}
        onClosePriceChange_15day={handleClosePriceChange_15day}
      >
        <>{type && <SwapForm type={type} tabs={tabs} />}</>
      </SwapPage>
    </Container>
  )
}

export default memo(Swap)

import Modal, { useModal } from "components/Modal";
import Checkbox from "components/Checkbox";
import Button from "components/Button";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  width: 100%;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.35);
  background-color: #fff;
  padding: 30px 0px;

  color: #5c5c5c;
  & > div {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
    padding: 0 30px;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #5c5c5c;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint}) {
    padding: 30px 0px;
    & > div {
      padding: 0 16px;
    }
  }
`;

const ModalTitle = styled.div`
  display: block;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #0222ba;
  margin-bottom: 30px;
`;

interface Peer2PeerDisclaimerProps {
  onAgree: () => void;
}

const Peer2PeerDisclaimer = forwardRef(({ onAgree }: Peer2PeerDisclaimerProps, ref) => {
  const [disclaimerAgreed, setP2pDisclaimerAgreed] = useState(false);
  const CreateContractNoticeModal = useModal();

  useImperativeHandle(ref, () => ({
    openModal: () => {
      CreateContractNoticeModal.open();
    },
    isAgreed: () => {
      return disclaimerAgreed;
    }
  }));

  const handleAgreeAndProceed = () => {
    console.log("Agree and Proceed clicked");
    CreateContractNoticeModal.close();
    setP2pDisclaimerAgreed(true);
    onAgree(); // Call the passed onAgree function
  };

  return (
    <Modal isOpen={CreateContractNoticeModal.isOpen} close={() => {}} open={() => {}}>
      <ModalContent>
        <div>
          <ModalTitle>Create Contract</ModalTitle>
          <div>
            A smart contract on Classic will be created for the connected wallet. This allows
            for the non-custodial and decentralized exchange between two peers.
            <br />
            <br />
            The BASE P2P marketplace will NOT hold your assets, but rather, your smart contract (under your control) will.
          </div>
          <br />
          <div style={{ marginBottom: 30 }}>

            I acknowledge and agree that I am responsible for various losses of
            assets by making transactions on the BASE P2P marketplace. The entities 
            involved in this site are not liable for any damages resulting from my
            use this platform. Use is at your own risk.
          </div>
          <div
            style={{ textAlign: "center", padding: "0 26px", marginBottom: 30 }}
          >
            <Checkbox
              onClick={() => setP2pDisclaimerAgreed((current) => !current)}
              checked={disclaimerAgreed}
            >
              I understand the risks and want a smart contract created for me.
            </Checkbox>
          </div>
          <Button
            size="lg"
            disabled={!disclaimerAgreed}
            onClick={handleAgreeAndProceed}
            style={{ textTransform: "unset", maxWidth: 235, borderRadius: 10 }}
          >
            Agree and Proceed
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
});

export default Peer2PeerDisclaimer;

/*
function onAgree() {
  throw new Error("Function not implemented.");
}
  */
import { Route, Routes, Navigate } from "react-router-dom"
import PtoP_base from "pages/Dashboard/PtoP_base"
import PtoP_frog from "pages/Dashboard/PtoP_frog"
import PtoP_lunw from "pages/Dashboard/PtoP_lunw"
import PtoP_frg from "pages/Dashboard/PtoP_frg"
import PtoP_grdx from "pages/Dashboard/PtoP_grdx"
import PtoP_food from "pages/Dashboard/PtoP_food"
import PtoP_lnc from "pages/Dashboard/PtoP_lnc"
import PtoP_trit from "pages/Dashboard/PtoP_trit"
import PtoP_terra from "pages/Dashboard/PtoP_terra"
import PtoP_dth from "pages/Dashboard/PtoP_dth"
import PtoP_rakoff from "pages/Dashboard/PtoP_rakoff"
import PtoP_catwifhat from "pages/Dashboard/PtoP_catwifhat"
import PtoP_orderbook from "pages/Dashboard/orderbook"




export default () => (
  <Routes>
    <Route path="/ptop_base" element={<PtoP_base />} />
    <Route path="/ptop_frog" element={<PtoP_frog/>} />
    <Route path="/ptop_lunw" element={<PtoP_lunw/>} />
    <Route path="/ptop_frg" element={<PtoP_frg/>} />
    <Route path="/ptop_grdx" element={<PtoP_grdx/>} />
    <Route path="/ptop_food" element={<PtoP_food/>} />
    <Route path="/ptop_lnc" element={<PtoP_lnc/>} />
    <Route path="/ptop_trit" element={<PtoP_trit/>} />
    <Route path="/orderbook" element={<PtoP_orderbook/>} />
    <Route path="/ptop_terra" element={<PtoP_terra/>} />
    <Route path="/ptop_dth" element={<PtoP_dth/>} />
    <Route path="/ptop_rakoff" element={<PtoP_rakoff/>} />
    <Route path="/ptop_catwifhat" element={<PtoP_catwifhat/>} />
    <Route path="*" element={<Navigate to="/ptop_base" replace />} />
  </Routes>
)

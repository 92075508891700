export const LUNA = "uluna"

export const cwLUNA = "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6"

export const BASE = "terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m"

export const FROG = "terra1wez9puj43v4s25vrex7cv3ut3w75w4h6j5e537sujyuxj0r5ne2qp9uwl9"

export const BINDER_ADDR = "terra1uskz68jrapecu87fp0ay3yq9luyespvcufnssrn48dk5arnx3mhqjm7wlt"

export const PAIRS = {
    market1: {
      market: "BASE-LUNC",
      b_symbol: "BASE",
      b_address: "terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m",
      q_symbol: "LUNC",
      q_address: "uluna",
      tableKey: "terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m-uluna",
    },
    market2: {
      market: "Market 2",
      b_symbol: "SYMBOL2",
      b_address: "terra1xyz...",
      q_symbol: "SYMBOL3",
      q_address: "uother",
      tableKey: "terra1xyz...-uother",
    },
    // Add other markets as needed
  };
  
import React, { useState, useEffect, useRef } from "react"
import { TxResult, useWallet } from "@terra-money/wallet-provider"
import { useNetwork, useContract, useAddress, useConnectModal } from "hooks"
import { Coin, Coins, CreateTxOptions, Fee, MsgSend, SignerInfo, int, } from "@terra-money/terra.js"
import { useLCDClient } from "layouts/WalletConnectProvider"
import { MsgInstantiateContract, MsgExecuteContract } from "@terra-money/terra.js"
import useGasPrice from "rest/useGasPrice"

import 'orderbook.css'
import { ULUNA, cwLUNA, BASE, FROG, BINDER_ADDR, PAIRS } from "./coins_tokens"
import styled from "styled-components"
import Container from "components/Container"
import Result from "forms/Result"
import Peer2PeerDisclaimer from "components/CreateContractNotice"
import { useModal } from "components/Modal"

import BASE_ICON from 'images/Token/LBUNC_128.png'

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding-left: 20px; 
  padding-right: 20px;
  `
function OrderBook() {
  const tokenSymbol = 'BASE';
 
  const wallet = useWallet()
  const walletAddress = useAddress()
  const connectModal = useConnectModal()
  const { terra } = useLCDClient()
  const { gasPrice } = useGasPrice("uluna")


  const [result, setResult] = useState()
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);

  const [userAddress, setUserAddress] = useState('');
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [inputFlag, setInputFlag] = useState('');
  const [connected, setConnected] = useState(false);

  const [basePrice, setBasePrice] = useState(0); // State to store the fetched spot price
  const [luncPrice, setLuncPrice] = useState(0)
  const [orderType, setOrderType] = useState('buy');
  const [amount, setAmount] = useState('');
  const [tableData, setTableData] = useState([])
  const [ownerTableData, setOwnerTableData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [contractAddress, setContractAddress] = useState();
  let [price, setPrice] = useState("")
  let [quantity, setQuantity] = useState("")
  const [tax, setTax] = useState("")
  const [total, setTotal] = useState("")
  const [rate, setRate] = useState("")

  let [orderPrice, setOrderPrice] = useState(0)
  let [orderSize, setOrderSize] = useState(0)
  let [orderFee, setOrderFee] = useState(0)
  let [orderTotal, setOrderTotal] = useState(0)
  let [orderId, setOrderId] = useState(0)
  let [orderContract, setOrderContract] = useState("")

  const [selectedOption, setSelectedOption] = useState("market1");

  const [myBuyOrders, setMyBuyOrders] = useState([]);
  const [mySellOrders, setMySellOrders] = useState([]);

  const peer2PeerDisclaimerRef = useRef(null);
  const [isOwnerEntryUndefined, setIsOwnerEntryUndefined] = useState(false);


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const prevWalletAddressRef = useRef();
  
  useEffect(() => {
    if (prevWalletAddressRef.current && prevWalletAddressRef.current !== walletAddress) {
      window.location.reload();
    }
    prevWalletAddressRef.current = walletAddress;
  }, [walletAddress]);
  
  

  useEffect(() => {
    fetchLuncPrice();
  }, []);

  useEffect(() => {
    fetchBasePrice();
  }, []);

  useEffect(() => {
    fetchOwnerTable();
  }, []);

  useEffect(() => {
    fetchBinderData();
  }, []);

 
  useEffect(() => {

    if (ownerTableData && ownerTableData.length > 0) {
      const ownerEntry = ownerTableData.find(entry => entry.owner === walletAddress);

      if (!ownerEntry) {
        setIsOwnerEntryUndefined(true);
      } else {
        setIsOwnerEntryUndefined(false);
      }
    }
  
  }, [ ownerTableData]);

  const instantiateNewContract = async () => {
    if (walletAddress) {
      try {
        await instantiateContract(walletAddress);
      } catch (error) {
        console.error("Error instantiating contract:", error);
        setResult(error);
        setIsResultModalOpen(true);
      }
    } else {
      connectModal.open();
    }
  };


  const handleAgreement = async () => {
    if (walletAddress) {
      await checkAndInstantiateContract(walletAddress);
      await fetchOwnerTable();
      await fetchBinderData();
    }
  };

  const checkAndInstantiateContract = async (walletAddress) => {
    peer2PeerDisclaimerRef.current.openModal();
    const newContractAddress = await instantiateContract(walletAddress);
  };

  useEffect(() => {
    filterOrders();
  }, [walletAddress, tableData]);

  const filterOrders = () => {
    if (walletAddress && tableData) {
      const buyOrders = tableData.filter(order => order.action === "buy" && order.owner === walletAddress);
      const sellOrders = tableData.filter(order => order.action === "sell" && order.owner === walletAddress);

      // Set these filtered orders in state
      setMyBuyOrders(buyOrders);
      setMySellOrders(sellOrders);
    }
  };

  // Use useEffect to populate price and quantity when wallet addr is available
  useEffect(() => {
    if (walletAddress && !isLoading) {
      const initialPrice = getMyPrice();
      const initialQuantity = getMyQuantity();
      setPrice(initialPrice);
      setQuantity(initialQuantity);
    }
  }, [walletAddress, isLoading]);

  const fetchLuncPrice = () => {
    const url3 =
      "https://graphsv2.coinpaprika.com/currency/data/luna-terra/24h/?quote=usd"
    fetch(url3)
      .then((response) => response.text())
      .then((text) => {
        //console.log(text)
        const apiCoinPaprikaLunc = JSON.parse(text)
        const priceArray = apiCoinPaprikaLunc[0].price;
        const latestData = priceArray[priceArray.length - 1];
        const [timestamp, price] = latestData;
        setLuncPrice(Number(price))
      })
      .catch((error) => console.error("Error fetching LUNC price:", error));
  };

  const fetchBasePrice = () => {
    const url = "https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m/smart/eyJjdXJ2ZV9pbmZvIjp7fX0=";
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let fetchedPrice = Number(data.data.spot_price) / 1000000;
        fetchedPrice = fetchedPrice * 1.058734; //account for taxes to get to buy price)
        setBasePrice(fetchedPrice);
      })
      .catch((error) => console.error("Error fetching spot price:", error));
  };

  const fetchBinderData = async () => {
    setIsLoading(true);
  
    try {
      // {"get_binder":{}} is eyJnZXRfYmluZGVyIjp7fX0=
      const binderResponse = await fetch(
        `https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/${BINDER_ADDR}/smart/eyJnZXRfYmluZGVyIjp7fX0=`
      );
      const binderData = await binderResponse.json();
  
      if (binderData && binderData.data && binderData.data.binder.tables) {
        const tables = binderData.data.binder.tables;
        const validResults = [];
  
        for (const tableKey in tables) {
          const table = tables[tableKey];
          if (table.rows && table.rows.length > 0) {
            table.rows.forEach(row => {
  
              const price = parseFloat(row.price) / 1000000;
              const size = parseFloat(row.size) / 1000000;
              const fees_tax = parseFloat(row.fees_tax) / 1000000;
              const total = parseFloat(row.total) / 1000000;
              const rate = total / size;
  
              validResults.push({
                cw20_address: tableKey.split('-')[0],
                ledger_id: row.ledger_id,
                order_id: row.order_id,
                owner: row.owner,
                contract: row.contract,
                action: row.action,
                price,
                size,
                fees_tax,
                total,
                rate,
              });
            });
          }
        }
  
        setTableData(validResults);
      }
  
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  
  const fetchOwnerTable = async () => {
    setIsLoading(true);
  
    try {
      // {"get_owner_table":{}} is eyJnZXRfb3duZXJfdGFibGUiOnt9fQ==
      const ownerTableResponse = await fetch(
        `https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/${BINDER_ADDR}/smart/eyJnZXRfb3duZXJfdGFibGUiOnt9fQ==`
      );
      const ownerTableData = await ownerTableResponse.json();
  
      if (ownerTableData && ownerTableData.data && ownerTableData.data.rows) {
        const rows = ownerTableData.data.rows;
        const ownerMapping = rows.map(row => ({
          contract: row.contract,
          owner: row.owner,
        }));
  
        setOwnerTableData(ownerMapping); // Update the state with the fetched data
      }
  
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching owner table data:", error);
      setIsLoading(false);
    }
  };
  

  const getMyPrice = () => {
    if (walletAddress) {
      const currentRow = tableData.find((row) => row.owner === walletAddress);
      if (currentRow) {
        return currentRow.price;
      }
    }
    return "No price found for the current wallet";
  };

  const getMyQuantity = () => {
    if (walletAddress) {
      const currentRow = tableData.find((row) => row.owner === walletAddress);
      if (currentRow) {
        return currentRow.quantity;
      }
    }
    return "No quantity found for the current wallet";
  };

  const populateAsks = () => {
    // Sort the tableData array based on the LUNC/BASE ratio (rate)
    const sortedData = tableData.sort((a, b) => a.rate - b.rate);
    const filteredData = sortedData.filter((row) => row.quantity > 1);

    return filteredData.map((row, index) => (
      <div key={`ask-${index}`} className="row">  
        <div className="price">{addCommasToNumber(row.price.toFixed(6))}</div>
        <div className="size order-size">{addCommasToNumber(row.quantity.toFixed(6))}</div>
      </div>
    ));
  };

  const handleOrderBookClick = (row) => {

    // Update state variables
    setOrderPrice(row.price.toFixed(6));
    setOrderSize(row.size.toFixed(6));
    setOrderFee(row.fees_tax.toFixed(6));
    setOrderTotal((row.total - row.fees_tax).toFixed(6));
    setOrderId(row.order_id);
    setOrderContract(row.contract);
  };

  function addCommasToNumber(number) {
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const findDetailsByPrice = (price) => {
    const result = tableData.find(item => item.price === price);
    if (result) {
      return {
        contract: result.contract,
        tax: result.fees_tax,
        total: result.total
      };
    }
    return "No details found with specified price";
  };

  const handleBuyClick = async (row, actionType) => {
    console.log("Buy button clicked");
    const executeMsg = {};
  
    if (orderTotal == 0) {
      orderTotal = orderPrice * orderSize
    }
      let uluncSent = Number(orderTotal) * 1.025;  // P2P fee
      uluncSent = uluncSent * 1.011;  // chain-tax
      uluncSent = uluncSent + 72.5; //
      uluncSent = Math.floor(uluncSent + 1) * 1000000;
      
    if (walletAddress) {
      try {
        let msgs = [];
        let matchingOrder = null;
        
        // Check for a matching sell order  
        for (const tableRow of tableData) {
          if (tableRow.price === Number(orderPrice) && tableRow.size >= Number(orderSize)) {
            matchingOrder = tableRow;
            break;
          }
        }
  
        // Get account info to retrieve sequence number and public key
        const accountInfo = await terra.auth.accountInfo(walletAddress);

        // Find the contract address for the wallet owner in the ownerTableData
        const ownerEntry = ownerTableData.find(entry => entry.owner === walletAddress);
        if (!ownerEntry){
          const error = new Error("Create a contract for this wallet address first");
          setResult(error);
          setIsResultModalOpen(true);
          return; // Early return to avoid further execution
        }

        if (matchingOrder) {
          // Match found - prepare execute message for the existing order
          executeMsg.fill_ask_native_to_cw20 = { 
            order_id: matchingOrder.order_id, 
            price: (Number(orderPrice) * 1000000).toString(), 
            size: (Number(orderSize) * 1000000).toString() 
          };
          let memo = "P2P_Fill_Ask";
    
          const msg = new MsgExecuteContract(
            walletAddress,
            matchingOrder.contract,
            executeMsg,
            [new Coin("uluna", uluncSent.toString())]
          );
  
          msgs = [msg];
        } else {
          // No match found - prepare execute message for a new buy order
          executeMsg.enter_bid_native_to_cw20 = { 
            denom_to: BASE, 
            price: (Number(orderPrice) * 1000000).toString(), 
            size: (Number(orderSize) * 1000000).toString() 
          };
          let memo = "P2P_Enter_Bid";
   
          const msg = new MsgExecuteContract(
            walletAddress,
            ownerEntry.contract, // Use the contract address from the ownerTableData
            executeMsg,
            [new Coin("uluna", uluncSent.toString())]
          );
  
          msgs = [msg];
        }
  
        const txOptions = {
          msgs,
          memo: matchingOrder ? "P2P_Fill_Ask" : "P2P_Enter_Bid",
          gasPrices: `${gasPrice}uluna`,
          gasAdjustment: 2.0,
        };
 
        if (txOptions.gasPrices == 'undefineduluna') {
          txOptions.gasPrices = '29.000uluna'
        }

        // Simulate the transaction to get estimated fees
        const simulationFeeResult = await terra.tx.estimateFee(
          [
            {
              sequenceNumber: accountInfo.getSequenceNumber(),
              publicKey: accountInfo.getPublicKey(),
            },
          ],
          txOptions
        );
  
        terra.tx.create([{ address: walletAddress }], txOptions)
          .then((signMsg) => {
            const fee = signMsg.auth_info.fee.amount.add(
              new Coin("uluna", simulationFeeResult.amount._coins.uluna.amount)
            );

            const biggerFee = new Coin(
              "uluna",
              Math.floor(Number(fee.get("uluna")?.amount) * 1.0)
            );

            txOptions.fee = new Fee(Number(simulationFeeResult.gas_limit) * txOptions.gasAdjustment, 
              new Coins([biggerFee]));
  
            return wallet.post(txOptions, walletAddress);
          })
          .then((extensionResult) => {
            if (extensionResult) {
              setResult(extensionResult);
              setIsResultModalOpen(true);
            }
          })
          .catch((error) => {
            console.error(error);
            const errorMessage = error.message || "An error occurred during the transaction";
            setResult(error);
            setIsResultModalOpen(true);
          });
      } catch (error) {
        console.error(error);
        const errorMessage = error.message || "An error occurred during the transaction";
        setResult(error);
        setIsResultModalOpen(true);
      }
    } else {
      connectModal.open();
    }
  
    if (orderSize > 0 && orderPrice > 0) {
      console.log(`Submitting buy order for ${orderSize} ${tokenSymbol} at price ${orderPrice} LUNC`);
      // Further API call to submit this data or internal method to handle the trade
    } else {
      console.log("Invalid order size or price");
    }
  };
  
  const handleSellClick = async (row, actionType) => {
    console.log("Sell button clicked");
    const executeMsg = {};
  
    if (walletAddress) {
      try {
        let msgs = [];
        let matchingOrder = null;

        // Get the selected market details
        const selectedPair = PAIRS[selectedOption];
  
        // Filter the table data corresponding to the selected market's table key
        const filteredTableData = tableData.filter(data => data.cw20_address === selectedPair.b_address);

        // Check for a matching buy order
        for (const tableRow of filteredTableData) {
          if (tableRow.price === Number(orderPrice) && tableRow.size >= Number(orderSize)) {
            matchingOrder = tableRow;
            break;
          }
        }
  
        // Get account info to retrieve sequence number and public key
        const accountInfo = await terra.auth.accountInfo(walletAddress);

        // Find the contract address for the wallet owner in the ownerTableData
        const ownerEntry = ownerTableData.find(entry => entry.owner === walletAddress);
        if (!ownerEntry){
          const error = new Error("Create a contract for this wallet address first");
          setResult(error);
          setIsResultModalOpen(true);
          return; // Early return to avoid further execution
        }
     
      if (matchingOrder) {
        // Match found - prepare execute message for filling the bid (send CW20 tokens)

        // Make Fill message and encode
        const fillMsg = {
          FillBidCw20ToNative: {
            order_id: matchingOrder.order_id,
            price: (orderPrice * 1000000).toString(),
          },
        };

        const encodedMsg = btoa(JSON.stringify(fillMsg));

        // Message to client's personal contract
        executeMsg.send = {
          contract: matchingOrder.contract,
          amount: (orderSize * 1000000).toString(),
          msg: encodedMsg,
        };

        let memo = "P2P_Fill_Bid";

        // Route through CW20 token contract
        const msg = new MsgExecuteContract(
          walletAddress,
          selectedPair.b_address, // The contract address of the CW20 token
          executeMsg,
          {}
        );

        msgs = [msg];

        } else {
        // No match found - prepare execute message for a new ask order (send CW20 tokens)
                
        // make Ask message and encode
        const askMsg = {
          EnterAskCw20ToNative: {
            price: (orderPrice * 1000000).toString(),
          },
        };
        const encodedMsg = btoa(JSON.stringify(askMsg));

        // message to client's personal contract
        executeMsg.send = {
          contract: ownerEntry.contract, // Use the contract address from the ownerTableData
          amount: (orderSize * 1000000).toString(),
          msg: encodedMsg,
        };

        let memo = "P2P_Enter_Ask";

        // Route through cw20 token contract
        const msg = new MsgExecuteContract(
          walletAddress,
          selectedPair.b_address, // The contract address of the CW20 token
          executeMsg,
          {}
        );

        msgs = [msg];

        }
  
        const txOptions = {
          msgs,
          memo: matchingOrder ? "P2P_Fill_Bid" : "P2P_Enter_Ask",
          gasPrices: `${gasPrice}${"uluna"}`,
          gasAdjustment: 1.90,
        };
  
        // Simulate the transaction to get estimated fees
        const simulationFeeResult = await terra.tx.estimateFee(
          [
            {
              sequenceNumber: accountInfo.getSequenceNumber(),
              publicKey: accountInfo.getPublicKey(),
            },
          ],
          txOptions
        );
  
        terra.tx.create([{ address: walletAddress }], txOptions)
          .then((signMsg) => {
            const fee = signMsg.auth_info.fee.amount.add(
              new Coin("uluna", simulationFeeResult.amount._coins.uluna.amount)
            );
  
            const biggerFee = new Coin(
              "uluna",
              Math.floor(Number(fee.get("uluna")?.amount) * 2.0)
            );
  
            txOptions.fee = new Fee(simulationFeeResult.gas_limit, new Coins([biggerFee]));
  
            return wallet.post(txOptions, walletAddress);
          })
          .then((extensionResult) => {
            if (extensionResult) {
              setResult(extensionResult);
              setIsResultModalOpen(true);
            }
          })
          .catch((error) => {
            console.error(error);
            const errorMessage = error.message || "An error occurred during the transaction";
            setResult(error);
            setIsResultModalOpen(true);
          });
      } catch (error) {
        console.error(error);
        const errorMessage = error.message || "An error occurred during the transaction";
        setResult(error);
        setIsResultModalOpen(true);
      }
    } else {
      connectModal.open();
    }
  
    if (orderSize > 0 && orderPrice > 0) {
      console.log(`Submitting sell order for ${orderSize} ${tokenSymbol} at price ${orderPrice} LUNC`);
      // Further API call to submit this data or internal method to handle the trade
    } else {
      console.log("Invalid order size or price");
    }
  };

  const handleCancelClick = async (order_id, contract_address) => {
    const executeMsg = {};
  
    if (walletAddress) {
      try {
        let msgs = [];
        executeMsg.cancel_order = { order_id: Number(order_id) };
        let memo = "Cancel Order";
        // Get account info to retrieve sequence number and public key
        const accountInfo = await terra.auth.accountInfo(walletAddress);
  
        const msg = new MsgExecuteContract(
          walletAddress,
          contract_address,
          executeMsg,
        );
        
        msgs = [msg];

        const txOptions = {
          msgs,
          memo,
          gasPrices: `${gasPrice}${"uluna"}`,
          gasAdjustment: 2.0,
        };
  
        // Simulate the transaction to get estimated fees
        const simulationFeeResult = await terra.tx.estimateFee(
          [
            {
              sequenceNumber: accountInfo.getSequenceNumber(),
              publicKey: accountInfo.getPublicKey(),
            },
          ],
          txOptions
        );

        const estimatedFeeAmount = simulationFeeResult.amount;
        const estimatedGasAmount = (simulationFeeResult.gas_limit * 1);

        const fee = new Fee(estimatedGasAmount, estimatedFeeAmount);
    
        terra.tx.create([{ address: walletAddress }], txOptions)
          .then((signMsg) => {

            //txOptions.fee = fee;

            return wallet.post(txOptions, walletAddress);
          })
          .then((extensionResult) => {
            if (extensionResult) {
              setResult(extensionResult);
              setIsResultModalOpen(true);
            }
          })
          .catch((error) => {
            console.error(error);
            const errorMessage = error.message || "An error occurred during the transaction";
            setResult(error);
            setIsResultModalOpen(true);
          });
      } catch (error) {
        console.error(error);
        const errorMessage = error.message || "An error occurred during the transaction";
        setResult(error);
      }
    } else {
      connectModal.open();
    }
  };

  const instantiateContract = async (walletAddress) => {
    const codeId = 9001; // Replace with your actual code ID
    const instantiateMsg = {
      manager: "default",
      client: walletAddress,
      ledger: "default",
    };
    const initMsg = new MsgInstantiateContract(
      walletAddress, // Sender
      "terra1vgdez00kxrdnj5xkzey3nvzxj8rjgw0rspxgpw", // Admin
      codeId,
      instantiateMsg,
      {}, // No LUNC sent
      "LBUN_P2P_Market"
    );

    const txOptions = {
      msgs: [initMsg],
      gasPrices: `${gasPrice}${"uluna"}`,
      gasAdjustment: 1.90,
    };
  
    if (txOptions.gasPrices == 'undefineduluna') {
      txOptions.gasPrices = '29.000uluna'
    }


    try {
      const accountInfo = await terra.auth.accountInfo(walletAddress);

      // Simulate the transaction to get estimated fees
      const simulationFeeResult = await terra.tx.estimateFee(
        [
          {
            sequenceNumber: accountInfo.getSequenceNumber(),
            publicKey: accountInfo.getPublicKey(),
          },
        ],
        txOptions
      );

      const estimatedFeeAmount = simulationFeeResult.amount;
      const estimatedGasAmount = simulationFeeResult.gas_limit;

      const fee = new Fee(estimatedGasAmount, estimatedFeeAmount);
      txOptions.fee = fee;
 
      terra.tx.create([{ address: walletAddress }], txOptions)
      .then((signMsg) => {

        //txOptions.fee = fee;

        return wallet.post(txOptions, walletAddress);
      })
      .then((extensionResult) => {
        if (extensionResult) {
          setResult(extensionResult);
          setIsResultModalOpen(true);
        }
      })
      .catch((error) => {
        console.error(error);
        const errorMessage = error.message || "An error occurred during the transaction";
        setResult(error);
        setIsResultModalOpen(true);
      });
    } catch (error) {
      console.error(error);
      const errorMessage = error.message || "An error occurred during the transaction";
      setResult(error);
      setIsResultModalOpen(true);
    }
    return undefined; // Return undefined if instantiation fails
  };
  
  const handleChangePrice = (e) => {
    const value = e.target.value.replace(/[^0-9.]+/g, '');
    const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
    setOrderPrice(value); // Keep the value with commas for display
    const total = numericValue * (parseFloat(orderSize.replace(/,/g, '')) || 0);
    setOrderTotal(total.toFixed(3));
  };
  
  const handleChangeAmount = (e) => {
    const value = e.target.value.replace(/[^0-9.]+/g, '');
    const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
    setOrderSize(value); // Keep the value with commas for display
    const total = (parseFloat(orderPrice.replace(/,/g, '')) || 0) * numericValue;
    setOrderTotal(total.toFixed(3));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process the order submission here
    console.log('Order Submitted:', { orderType, price, amount });
  };

  const handleFailure = () => {
    setTimeout(() => { }, 125)
    //setResult("There was a problem. Please try again later.")
    window.location.reload()
  }

  function Dropdown({ selectedOption, handleOptionChange }) {
    return (
      <div className="dropdown-container">
        <select id="market-dropdown" value={selectedOption} onChange={handleOptionChange}>
          {Object.keys(PAIRS).map((key) => (
            <option key={key} value={key}>
              {PAIRS[key].market}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <Wrapper>
      <Peer2PeerDisclaimer ref={peer2PeerDisclaimerRef} onAgree={handleAgreement} />
      {isResultModalOpen && (
        <div className="modal-overlay">
          <Container sm>
            <Result
              response={result}
              error={(result instanceof Error && result) || result === "User Denied" ? result : undefined}
              parserKey={"default"}
              onFailure={handleFailure}
            />
          </Container>
        </div>
      )}
      <div className="flex flex-col items-center justify-center p-4 bg-gray-200">
        {isOwnerEntryUndefined && (
          <div className="buy-and-sell-button-wrapper">
          <button onClick={instantiateNewContract} className="instantiate-button button-valid">
            Instantiate New Contract
          </button>
          </div>
        )}
        </div>
      <div className="flex flex-col items-center justify-center p-4 bg-gray-200">
        <main className="main-wrapper">
          <div className="container">
            <div className="flipcard top-bar-item">
              <div className="left">
                <div className="pair-wrapper">
                  <img src={BASE_ICON} alt="base icon"></img>
                  <div id="pair" className="user-input-entered">{tokenSymbol}</div>
                </div>
                <div className="select-market-wrapper">
                  <div id="select-a-market" className="top-bar-description">Market:</div>
                  <Dropdown
                    selectedOption={selectedOption}
                    handleOptionChange={handleOptionChange}
                  />
                  {/*<img src="./images/bx-chevron-down.svg" alt="dropdown button" />*/}
                </div>
              </div>
              <div className="right">
                <div className="top-bar-item">
                  <div className="top-bar-description">Price (LUNC)</div>
                  <div id="price-oracle-value" className="top-bar-value"> {basePrice.toFixed(6)}</div>
                </div>
                <div className="top-bar-item">
                  <div className="top-bar-description">Price (USD)</div>
                  <div id="block-number-value" className="top-bar-value">${(basePrice * luncPrice).toFixed(3)}</div>
                </div>
              </div>
            </div>
            <div className="flipcard main-item-left">
              <p className="title-selected">Limit Order</p>
              <div className="not-title-wrapper">
                <div className="top-wrapper">
                  <div className="label-wrapper">
                    <div className="label-user-input">
                      <p className="label-user-input-text">Price</p>
                      <div className="tooltip">
                        <img className="question-circle-icon" src="./images/circle-question-regular.svg" alt="explanation icon"></img>
                        <span className="tooltiptext">The price of 1 {tokenSymbol}</span>
                      </div>
                    </div>
                    <div className="inputs">
                      <div className="input-wrapper">
                      <input
                        id="usd-size"
                        type="tel"
                        className="user-input-entered"
                        placeholder="0.00"
                        value={addCommasToNumber(orderPrice)}
                        onChange={handleChangePrice}
/>
                        <p className="label-currency currency-next-to-size">LUNC</p>
                      </div>
                    </div>
                  </div>
                  <div className="label-wrapper">
                    <div className="label-user-input">
                      <p className="label-user-input-text">Size</p>
                      <div className="tooltip">
                        <img className="question-circle-icon" src="./images/circle-question-regular.svg" alt="explanation icon"></img>
                        <span className="tooltiptext">Amount of traded Asset</span>
                      </div>
                    </div>
                    <div className="inputs">
                      <div className="input-wrapper" id="eth-input-wrapper">
                      <input
                        id="eth-size"
                        type="tel"
                        className="user-input-entered"
                        placeholder="0.000"
                        value={addCommasToNumber(orderSize)}
                        onChange={handleChangeAmount}
                      />
                        <p className="label-currency currency-next-to-size">{tokenSymbol}</p>
                      </div>
                    </div>
                  </div>
                  <div className="label-wrapper">
                    <div className="label-user-input">
                      <p className="label-user-input-text">Total</p>
                      <div className="tooltip">
                        <img className="question-circle-icon" src="./images/circle-question-regular.svg" alt="explanation icon"></img>
                        <span className="tooltiptext">Total (w/o Fees)</span>
                      </div>
                    </div>
                    <div className="inputs">
                      <div className="input-wrapper">
                      <input
                        id="limit-price"
                        type="tel"
                        inputMode="decimal"
                        className="user-input-entered"
                        placeholder="0.00"
                        value={addCommasToNumber(orderTotal)}
                        readOnly
                      />
                        <p className="label-currency currency-next-to-size">LUNC</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-wrapper">
                  <div className="fee-table">
                    <div className="fee-row-wrapper">
                      <div className="header-cell-vertical fee-row-header">Fee</div>
                      <div id="fee-value" className="value-cell-vertical fee-row-value">{orderFee}</div>
                    </div>
                    <div className="fee-row-wrapper">
                      <div className="header-cell-vertical fee-row-header">Order Value</div>
                      <div id="total-value" className="value-cell-vertical fee-row-value">{(Number(orderTotal) + Number(orderFee)).toFixed(6)}</div>
                    </div>
                  </div>
                  <div className="buy-and-sell-button-wrapper">
                    <button id="buy-button" className="buy-button button-valid" onClick={handleBuyClick} >Buy {tokenSymbol}</button>
                    <button id="sell-button" className="sell-button button-valid" onClick={handleSellClick} >Sell {tokenSymbol}</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flipcard main-item-right">
              <p className="title-selected">Orderbook</p>
              <div className="orderbook-wrapper">
                <div className="header header-cell-vertical">
                  <div className="one">Limit Price (LUNC)</div>
                  <div className="two">Size ({tokenSymbol})</div>
                </div>
                <div id="ob-table" className="main">
                  {/* Render the sell orders */}
                  <div className="sell-ob sell-price">
                    {tableData
                      .filter(data => data.action === "sell")
                      .sort((a, b) => a.price - b.price) // Sorting by price in ascending order
                      .map((data, index) => (
                        <div
                          key={`ask-${index}`}
                          className="row"
                          onClick={() => handleOrderBookClick(data)}
                        >
                          <div className="price">{data.price.toFixed(6)}</div>
                          <div className="size order-size">{(data.size).toFixed(6)}</div>
                        </div>
                      ))}
                  </div>
                  <div className="mid-bar header-cell-vertical">
                    <div className="one">-</div>
                    <div className="two">Spread</div>
                  </div>
                  {/* Render the buy orders */}
                  <div className="buy-ob buy-price">
                    {tableData
                      .filter(data => data.action === "buy")
                      .sort((a, b) => b.price - a.price) // Sorting by price in ascending order
                      .map((data, index) => (
                        <div
                          key={`buy-${index}`}
                          className="row"
                          onClick={() => handleOrderBookClick(data)}
                        >
                          <div className="price">{data.price.toFixed(6)}</div>
                          <div className="size order-size">{(data.size).toFixed(6)}</div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flipcard bottom-main">
              <p className="title-selected">My Active Orders</p>
              <div className="active-orders-table-wrapper">
                <header className="header header-cell-vertical">
                  <div className="one">Side</div>
                  <div className="two">Price (LUNC)</div>
                  <div className="three">Size ({tokenSymbol})</div>
                  <div className="four">Value (USD)</div>
                  <div className="five">Action</div>
                </header>
                <div id="active-orders-main">
                {myBuyOrders.length > 0 ? (
          myBuyOrders.map(order => (
            <div key={order.order_id} className="row value-cell-vertical">
              <div className="buy-side one">BUY</div>
              <div className="two">{order.price.toFixed(6)}</div>
              <div className="three">{order.size.toFixed(6)}</div>
              <div className="four">${(order.total * luncPrice).toFixed(3)}</div>
              <div className="five">
              <button className="buy-price cancel-button" onClick={() => handleCancelClick(order.order_id, order.contract )}>
                Cancel</button>
              </div>
            </div>
          ))
        ) : (
          <div className="row value-cell-vertical">
            <div className="buy-side one">BUY</div>
            <div className="two"></div>
            <div className="three"></div>
            <div className="four"></div>
            <div className="five"></div>
          </div>
        )}
        {mySellOrders.length > 0 ? (
          mySellOrders.map(order => (
            <div key={order.order_id} className="row value-cell-vertical">
              <div className="sell-side one">SELL</div>
              <div className="two">{order.price.toFixed(6)}</div>
              <div className="three">{order.size.toFixed(6)}</div>
              <div className="four">${(order.total * luncPrice).toFixed(3)}</div>
              <div className="five">
              <button className="sell-price cancel-button" onClick={() => handleCancelClick(order.order_id, order.contract )}> 
                Cancel 
              </button>
              </div>
            </div>
          ))
        ) : (
          <div className="row value-cell-vertical">
            <div className="sell-side one">SELL</div>
            <div className="two"></div>
            <div className="three"></div>
            <div className="four"></div>
            <div className="five"></div>
          </div>
        )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Wrapper>
  );
}

export default OrderBook;
